import { BackendSrvRequest } from '@grafana/runtime';

import { TenantStatsEnricher } from '@common/api';
import { TenantStats } from '@common/types';
import { apiPost } from '@common/utils';

import { getSystemMonitoringDatasourceURL } from './datasources';

// These provide more accurate values for active series counts using a system monitoring query
export const tenantActiveSeriesCountsEnricher: TenantStatsEnricher = async (stats: TenantStats[]) => {
  // Update stats using consitent active series counts
  const activeSeriesCounts = await fetchConsistentActiveSeriesCounts();

  activeSeriesCounts?.forEach((activeSeriesCount) => {
    const tenantStats = stats.find((stats) => stats.name === activeSeriesCount.tenant);
    if (tenantStats) {
      tenantStats.numberOfSeries = activeSeriesCount.count;
    }
  });
};

// See https://github.com/grafana/gex-plugins/issues/332
const fetchConsistentActiveSeriesCounts = async () => {
  const url: string | undefined = await getSystemMonitoringDatasourceURL();

  if (!url) {
    return undefined;
  }

  const options: Partial<BackendSrvRequest> = {
    data: { query: QUERY_ACTIVE_SERIES_COUNT },
    headers: { ['Content-Type']: 'application/x-www-form-urlencoded' },
  };

  const response = (await apiPost<ActiveSeriesCountResponse>(`${url}/api/v1/query`, options)).data;

  const counts: ActiveSeriesCount[] = response.data.result.map((metricData) => {
    const [_, count] = metricData.value;

    return {
      count,
      tenant: metricData.metric.user,
    };
  });

  return counts;
};

type ActiveSeriesCountResponse = {
  data: {
    result: Array<{
      metric: {
        user: string;
      };
      value: number[]; // timestamp, value
    }>;
  };
};

type ActiveSeriesCount = {
  count: number;
  tenant: string;
};

const QUERY_ACTIVE_SERIES_COUNT = `
sum by (user) (
  (
      cortex_ingester_memory_series_created_total{}
    -
      cortex_ingester_memory_series_removed_total{}
  )
  / on(cluster) group_left()
  max by(cluster) (
    cortex_distributor_replication_factor
  )
)
`
  .replace(/\s+/g, ' ')
  .trim();
