import {
  AccessPolicyScopeFeatures,
  BackendFeatures,
  BackendType,
  BASE_SCOPES_BY_BACKEND_TYPE,
  DEFAULT_SCOPES_BY_BACKEND_TYPE,
  ImplicitBackendFeatures,
  READ_SCOPE_BY_BACKEND_TYPE,
  RING_TYPES_BY_BACKEND_TYPE,
  Scope,
} from '@common/types';

import { GEM_TENANT_CUSTOMIZABLE_LIMITS } from './limits';

// This helps determine the features and differences based implicitly
// on the backend type.
// In the future, some of these will be determined by the more explicit
// `features` which come from the `/features` endpoint and will need to be removed
// from here accordingly
export const getImplicitFeatures = (backendType: BackendType): ImplicitBackendFeatures => {
  // GET uses a slightly different URL for its config.
  const isGET = backendType === 'GET';
  const configFetchUrl = isGET ? 'status/config' : 'config';

  const isGEM = backendType === 'GEM';
  const tenantCustomizableLimits = isGEM ? GEM_TENANT_CUSTOMIZABLE_LIMITS : [];
  const tenantStatsEnabled = isGEM;

  return {
    configFetchUrl,
    ringTypes: RING_TYPES_BY_BACKEND_TYPE[backendType],
    tenantCustomizableLimits,
    tenantStatsEnabled,
  };
};

// These are kind of a mix of implicit and explicit features
export const getAccessPolicyScopeFeatures = (
  backendType: BackendType,
  features: Partial<BackendFeatures>
): AccessPolicyScopeFeatures => {
  const readScope = READ_SCOPE_BY_BACKEND_TYPE[backendType];

  const defaultScopes = DEFAULT_SCOPES_BY_BACKEND_TYPE[backendType];

  const availableScopes = [...BASE_SCOPES_BY_BACKEND_TYPE[backendType]];

  // Append feature-enabled scopes
  if (features.block_upload) {
    if (backendType === 'GEM') {
      // Currently only GEM has a special block_upload scope
      availableScopes.push(Scope.MetricsImport);
    }
  }

  availableScopes.sort();

  return {
    availableScopes,
    defaultScopes,
    readScope,
  };
};
