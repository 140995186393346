import { PluginMeta } from '@grafana/data';
import { config, getDataSourceSrv } from '@grafana/runtime';

import {
  createDatasource,
  createToken,
  deleteDatasource,
  getAccessPolicy,
  getDatasources,
  updateDatasource,
} from '@common/api';
import { AppPluginSettings, datasourceHasUrl, DatasourceType, getAdminApiPrefix, Token } from '@common/types';
import {
  apiPost,
  createUuid,
  removeTrailingSlashes,
  SYSTEM_MONITORING_ACCESS_POLICY_NAME,
  SYSTEM_MONITORING_TOKEN_NAME_PREFIX,
} from '@common/utils';

import selfMonitoringDashboardConfig from './dashboards/config.json';

const SYSTEM_MONITORING_DATASOURCE_NAME = selfMonitoringDashboardConfig.datasource;

export const initCardinalityDatasource = async () => {
  const type = 'grafana-cardinality-datasource';
  const name = 'GEM cardinality adapter';

  const cardinalityFilter = (datasource: { type: string }) => datasource.type === type;

  // Note: it only matters that a datasource of this type exists.
  if (Object.values(config.datasources).find(cardinalityFilter) || (await getDatasources()).find(cardinalityFilter)) {
    // Found a cardinality adapter in runtime config or from an api fetch
    return null;
  }

  const cardinalityDatasource = {
    name,
    access: 'proxy',
    basicAuth: false,
    isDefault: false,
    password: 'no',
    readOnly: true,
    type,
  };

  const result = await apiPost<any>('/api/datasources', { data: cardinalityDatasource });
  return result;
};

export const getSystemMonitoringDatasourceURL = async () => {
  try {
    const datasource = await getDataSourceSrv().get(SYSTEM_MONITORING_DATASOURCE_NAME);

    if (!datasourceHasUrl(datasource)) {
      return undefined;
    }

    return datasource.url;
  } catch (e) {
    // It does not exist, so return undefined
    return undefined;
  }
};

export const initSystemMonitoringDatasource = async (pluginMeta: PluginMeta<AppPluginSettings>) => {
  try {
    const adminApiPrefix = getAdminApiPrefix(pluginMeta.id);

    const baseUrl = removeTrailingSlashes(pluginMeta.jsonData?.backendUrl || '');
    if (baseUrl === '') {
      throw "The plugin's jsonData backendUrl is not configured.";
    }
    // Assume that we can trust `prometheus` to be the suffix
    const promSuffix = 'prometheus';
    const promUrl = `${baseUrl}/${promSuffix}`;

    const targetDatasource = {
      name: SYSTEM_MONITORING_DATASOURCE_NAME,
      basicAuthUser: SYSTEM_MONITORING_ACCESS_POLICY_NAME,
      jsonData: {},
      type: DatasourceType.Prometheus,
      url: promUrl, // Keep this blank, for default POST method
    };

    const accessPolicyName = targetDatasource.basicAuthUser;
    const accessPolicyResult = await getAccessPolicy(adminApiPrefix, accessPolicyName, { showErrorAlert: false });

    if (!accessPolicyResult) {
      throw `Could not find access policy: ${accessPolicyName}`;
    }

    // Determine if datasource already exists
    const existingDatasources = await getDatasources();
    const uppercaseName = targetDatasource.name.toUpperCase();

    const matches = existingDatasources.filter((datasource) => datasource.name.toUpperCase().trim() === uppercaseName);

    const perfectMatch = matches.find(
      (existingDatasource) =>
        existingDatasource.name === targetDatasource.name &&
        existingDatasource.type === targetDatasource.type &&
        existingDatasource.url === targetDatasource.url &&
        Object.keys(existingDatasource.jsonData).length === 0 // A perfect match will have zero entries here
    );

    if (perfectMatch !== undefined) {
      throw `Datasource ${targetDatasource.name} already exists.`;
    }

    // Determine if there are any non-perfect matches.
    // Occasionally we encounter the inability to make a
    // new data source with this specific ${datasourceName}
    // when an existing data source has a similar name,
    // but with different upper/lower case.
    // A similar issue may occur if the URL changes, or if the
    // user manually altered some of the other data source properties.
    // So any of the imperfect matches may become a candidate for repair.
    const candidateForRepair = matches[0];

    if (candidateForRepair) {
      // If there are any other matches, we will delete them first.
      matches.slice(1).forEach(async (match) => {
        if (match.id) {
          await deleteDatasource(match.id);
        }
      });

      const result = await updateDatasource({ ...candidateForRepair, ...targetDatasource });
      return result.datasource;
    }

    // In order to ensure that multiple Grafana instances pointing to the same GEM instance
    // don't clobber each other's tokens, we choose to not delete any tokens
    // when we discover that the data source does not exist.
    // See: See: https://github.com/grafana/gex-plugins/issues/519

    // Note that if the system monitoring data source is deleted, any orphaned tokens
    // from that deleted data source will remain, as the new token below will be created
    // for the new data source.

    const tokenParams: Token = {
      // Unique name to work around the following:
      // - issue https://github.com/grafana/backend-enterprise/issues/1678
      // - v3 onward, tokens are not actually deleted, but set to 'inactive'
      name: SYSTEM_MONITORING_TOKEN_NAME_PREFIX + '-' + createUuid(),
      access_policy: accessPolicyName,
      created_at: new Date(Date.now()).toISOString(),
      display_name: 'System Monitoring Token',
      expiration: undefined,

      status: 'active',
    };

    const createTokenResult = await createToken(adminApiPrefix, tokenParams);

    return await createDatasource({
      ...targetDatasource,
      basicAuthPassword: createTokenResult.token,
    });
  } catch (reason: any) {
    console.info('Did not set up system monitoring data source:', reason?.data?.message || reason);
    return false;
  }
};
