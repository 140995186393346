import React from 'react';

import { rangeUtil } from '@grafana/data';
import { RefreshPicker } from '@grafana/ui';

import { useLoadingRingTypes, useRingRefreshInterval } from '@common/state/rings';

export const RingHealthRefresh = () => {
  const {
    refresh,
    refreshInterval: refreshIntervalMilliseconds,
    setRefreshInterval: setRefreshIntervalMilliseconds,
  } = useRingRefreshInterval();
  const loadingRings = useLoadingRingTypes();
  const anyRequestsLoading = loadingRings.size > 0;

  const refreshInterval = MILLISECONDS_TO_INTERVAL.get(refreshIntervalMilliseconds);

  return (
    <RefreshPicker
      onRefresh={refresh}
      onIntervalChanged={(interval) => setRefreshIntervalMilliseconds(rangeUtil.intervalToMs(interval))}
      value={refreshInterval}
      isLoading={anyRequestsLoading}
    />
  );
};

const INTERVALS = ['5s', '10s', '30s', '1m', '5m', '15m', '30m', '2h', '1d'];

const MILLISECONDS_TO_INTERVAL = new Map(INTERVALS.map((interval) => [rangeUtil.intervalToMs(interval), interval]));
