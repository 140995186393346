import React, { useContext, useEffect } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconButton, Select, Tooltip, useStyles2 } from '@grafana/ui';

import { selectRequestInfo } from '@common/state/requests';
import { fetchAll, selectTenantsForAddingToAccessPolicies } from '@common/state/tenants';
import { AccessPolicy, Realm, Tenant } from '@common/types';
import {
  BackendContext,
  getNotSelectedTenants,
  isWildcardRealm,
  isWildcardTenant,
  useGetTenantDisplayName,
} from '@common/utils';

import { LabelPolicyEditor } from '../LabelPolicyEditor';
import { LoadingIndicator } from '../LoadingIndicator';
import { DetailedTooltip } from '../Utils';

type Props = {
  /** The control object coming from useHookForm(). */
  control: Control<AccessPolicy>;
  /** (Optional) If set to true it enables assigning label selectors to tenants. */
  enableLabelSelectors?: boolean;
};

export const RealmsEditor = ({ control, enableLabelSelectors = false }: Props) => {
  const {
    backend: {
      accessPolicyScopeFeatures: { readScope },
      features,
    },
  } = useContext(BackendContext);
  const name = 'realms';
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const unrestrictedTenants = useSelector(selectTenantsForAddingToAccessPolicies);
  const federatedQueriesEnabled = Boolean(features.federated_queries);
  const labelBasedAccessControl = Boolean(features.lbac);
  const isWildcardRestricted = !federatedQueriesEnabled;
  // Wildcards are only allowed when "federated queries" are enabled, so if that's not active we need to remove that option from the list
  const tenants = !isWildcardRestricted ? unrestrictedTenants : unrestrictedTenants.filter((tn) => tn.name !== '*');
  const getTenantByName = (name?: string) => tenants.find((t: Tenant) => t.name === name);
  const tenantsRequest = useSelector(selectRequestInfo('tenants/fetchAll'));
  const { register } = useFormContext();
  const {
    append,
    fields: realms,
    remove,
    replace,
  } = useFieldArray({
    name,
    control,
  });

  const getTenantDisplayName = useGetTenantDisplayName(tenants);

  const wildcardClusters = new Set(realms.filter(isWildcardRealm).map((realm) => realm.cluster));

  // If there is a wildcard in a cluster, we only want to see the wildcard entry. We hide all others.
  const isRealmVisible = (realm: Realm) => !wildcardClusters.has(realm.cluster) || isWildcardRealm(realm);
  const isTenantSelectable = (tenant: Tenant) => !wildcardClusters.has(tenant.cluster) || isWildcardTenant(tenant);

  const selectableTenants = getNotSelectedTenants(realms, tenants).filter(isTenantSelectable);

  useEffect(() => {
    tenantsRequest.isNotFetched && dispatch(fetchAll());
  }, []); // eslint-disable-line

  if (tenantsRequest.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className={styles.realmContainer}>
        {/* Realms */}
        {realms.length > 0 &&
          realms.map((realm, index) => {
            const hasTenant = tenants.some((tn) => tn.name === realm.tenant);

            return (
              isRealmVisible(realm) && (
                <div key={`${name}.${index}`} className={styles.metaDataWrapper}>
                  <div className={styles.hiddenInputWrapper}>
                    {/* Meta data */}
                    {/* Register them as hidden fields as we need react-hook-form to add them to the Realm object (it doesn't get picked up from "fields") */}
                    <input
                      {...register(`${name}.${index}.tenant`)}
                      type="hidden"
                      name={`${name}.${index}.tenant`}
                      value={realm.tenant}
                    />
                    <input
                      {...register(`${name}.${index}.cluster`)}
                      type="hidden"
                      name={`${name}.${index}.cluster`}
                      value={realm.cluster}
                    />

                    {/* Realm name */}
                    <div className={styles.realmNameWrapper}>
                      <div className={styles.warningIcon}>
                        {!hasTenant && (
                          <Tooltip
                            content="This tenant has been deactivated. Update this access policy accordingly."
                            placement="top-end"
                          >
                            <Icon name="exclamation-triangle" />
                          </Tooltip>
                        )}
                      </div>
                      <DetailedTooltip
                        label={getTenantDisplayName(realm.cluster, realm.tenant)}
                        maxWidth={css`
                          max-width: 200px;
                        `}
                        interactive={true}
                      />

                      <div className={styles.cloudIcon}>
                        <Icon name="cloud" /> {realm.cluster}
                      </div>
                    </div>

                    {labelBasedAccessControl && (
                      <>
                        {/* Label Policies */}
                        <div className={styles.labelPolicyWrapper}>
                          {enableLabelSelectors && <LabelPolicyEditor name={`${name}.${index}.label_policies`} />}
                          {!enableLabelSelectors && (
                            <div className={styles.labelPolicy} data-testid={'lbac-add-scope-message'}>
                              Label selectors only work for the read path.
                              <br />
                              Add the &quot;{readScope}&quot; scope to enable them.
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/* Remove Realm */}

                    <IconButton
                      aria-label='Delete'
                      type="button"
                      className={styles.removalButton}
                      data-testid="ap-delete"
                      name="trash-alt"
                      size="lg"
                      onClick={(e) => {
                        e.stopPropagation();

                        remove(index);
                      }}
                    />
                  </div>
                </div>
              )
            );
          })}

        {/* Add new Realm */}
        <div className={styles.realmSelectWrapper} data-testid="tenant-selector">
          <Select
            formatOptionLabel={({ label, value }) => <DetailedTooltip label={label} value={value} hidden />}
            width={30}
            menuPlacement="top"
            maxMenuHeight={180}
            key={`select-tenant-${selectableTenants.length}`}
            disabled={selectableTenants.length === 0}
            placeholder={selectableTenants.length > 0 ? 'Choose a tenant' : 'All tenants selected'}
            onChange={({ value }) => {
              const selectedTenant = getTenantByName(value);

              if (selectedTenant) {
                if (isWildcardTenant(selectedTenant)) {
                  replace([
                    {
                      cluster: selectedTenant.cluster,
                      label_policies: [],
                      tenant: selectedTenant.name,
                    },
                  ]);
                } else {
                  append({
                    cluster: selectedTenant.cluster,
                    label_policies: [],
                    tenant: selectedTenant.name,
                  });
                }
              }
            }}
            options={selectableTenants.map((tenant) => ({
              name: tenant.name,
              cluster: tenant.cluster,
              description: `Cluster: ${tenant.cluster}`,
              label: tenant.display_name,
              value: tenant.name,
            }))}
          />
        </div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    realmContainer: css`
      border-width: 1px;
      border-style: solid;
      border-color: ${theme.isDark ? '#3c3c3c' : '#e1e3e4'};
    `,
    metaDataWrapper: css`
      display: flex;
      padding: ${theme.spacing(1)};
      border-style: solid;
      border-color: ${theme.isDark ? '#3c3c3c' : '#e1e3e4'};
      border-width: 0 0 1px 0;
    `,
    hiddenInputWrapper: css`
      display: flex;
      width: 100%;
      margin-bottom: ${theme.spacing(2)};
    `,
    realmNameWrapper: css`
      width: 200px;
      padding: ${theme.spacing(1)};
    `,
    warningIcon: css`
      color: ${theme.colors.warning.text};
      display: inline;
      vertical-align: middle;
      margin-right: ${theme.spacing(0.5)};
    `,
    cloudIcon: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      color: ${theme.colors.text.secondary};
    `,
    labelPolicyWrapper: css`
      flex: 1;
      padding: ${theme.spacing(1)};
      margin-left: 48px;
    `,
    labelPolicy: css`
      color: ${theme.colors.text.secondary};
      font-size: ${theme.typography.bodySmall.fontSize};
    `,
    removalButton: css`
      color: ${theme.colors.text.secondary};
      cursor: pointer;
      margin-left: auto;
      order: 2;
    `,
    realmSelectWrapper: css`
      padding: ${theme.spacing(1)};
      display: flex;
      align-items: center;
    `,
  };
};
