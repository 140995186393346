import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectableValue } from '@grafana/data';
import { HorizontalGroup, Icon, IconName, InlineField, InlineFieldRow, Select, VerticalGroup } from '@grafana/ui';

import { fetchAll as fetchAllClusters, selectAll as selectAllClusters } from '@common/state/clusters';
import { selectRequestInfo } from '@common/state/requests';
import { useRingMetadata } from '@common/state/rings';
import { RingHealthByCluster } from '@common/types';
import { any, areAllRingsInactive, useUtilityStyles } from '@common/utils';

import { ClusterRingHealthLabel } from './ClusterRingHealthLabel';
import { RingHealthRefresh } from './RingHealthRefresh';
import { RingManager } from './RingManager';

export const ClusterRingHealth = () => {
  const dispatch = useDispatch();

  /*
   * REQUEST STATES
   */

  // Get request states
  const clusterFetchRequest = useSelector(selectRequestInfo(fetchAllClusters.typePrefix));

  /*
   * SET UP RING-HEALTH-BY-CLUSTER MAP (HACK for Singleton cluster)
   */

  const clusters = useSelector(selectAllClusters);
  const ringHealthByCluster = {} as RingHealthByCluster;

  {
    // Assumes there is only one... for now.
    const singletonCluster = { ...clusters[0] };

    const singletonClusterRingMetadata = useRingMetadata();

    if (singletonCluster != null) {
      // Set up singleton cluster
      ringHealthByCluster[singletonCluster.name] = singletonClusterRingMetadata;
    }
  }

  /*
   * STATES
   */

  // Indicate currently selected ring and cluster
  const [currentCluster, setCurrentCluster] = useState(clusters[0]?.name as string | undefined);

  /*
   * EFFECTS
   */

  // On first render, fetch cluster list.
  useEffect(() => {
    dispatch(fetchAllClusters());
  }, [dispatch]);

  // If clusters change, and if currentCluster is null, pick the first one.
  useEffect(() => {
    if (currentCluster === undefined && clusters.length > 0) {
      setCurrentCluster(clusters[0].name);
    }
  }, [clusters, currentCluster]);

  /*
   * RENDER ELEMENTS
   */
  const s = useUtilityStyles();

  // The row that summarizes the cluster, and allows selection and refreshing
  let clusterRow = null;

  {
    const clusterOptions = clusters.map((cluster) => {
      const option = {
        label: `${cluster.display_name} (${cluster.kind})`,
        value: cluster.name,
      } as SelectableValue<string>;

      const clusterRingMetadata = ringHealthByCluster[cluster.name];

      if (clusterRingMetadata) {
        const metadataList = Object.values(clusterRingMetadata);
        const unhealthy = any((metadata) => metadata.unhealthyMemberIds.length > 0, metadataList);

        if (unhealthy) {
          option.icon = 'exclamation-triangle';
          option.className = s.colorDanger;
        } else {
          if (areAllRingsInactive(metadataList)) {
            option.icon = 'exclamation-triangle';
            option.className = s.colorDanger;
          }
        }
      }

      return option;
    });

    const clusterOptionRenderer = ({ className, icon, label }: SelectableValue<string>) => (
      <HorizontalGroup className={className}>
        {icon && <Icon className={className} name={icon as IconName} />}
        <span className={className}>{label}</span>
      </HorizontalGroup>
    );

    const clustersHealthLabel = Object.keys(ringHealthByCluster).length > 0 && (
      <ClusterRingHealthLabel ringHealthByCluster={ringHealthByCluster} />
    );

    const clusterSelectionRow = (
      <InlineFieldRow>
        <InlineField loading={clusterFetchRequest.isLoading} label="Cluster">
          <Select
            getOptionLabel={clusterOptionRenderer}
            isLoading={clusterFetchRequest.isLoading}
            value={currentCluster}
            onChange={(item: SelectableValue<string>) => setCurrentCluster(item.value)}
            options={clusterOptions}
          />
        </InlineField>
        {clustersHealthLabel}
        <RingHealthRefresh />
      </InlineFieldRow>
    );

    clusterRow = clusterSelectionRow;
  }

  let ringManager;
  // Dumb way to check if the right cluster is selected
  if (ringHealthByCluster[String(currentCluster)]) {
    ringManager = <RingManager />;
  }

  return (
    <>
      <VerticalGroup spacing="lg">
        {clusterRow}
        {ringManager}
      </VerticalGroup>
    </>
  );
};
