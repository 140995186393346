type AppId = (
  'grafana-metrics-enterprise-app' |
  'grafana-enterprise-logs-app' |
  'grafana-enterprise-traces-app' |
  'grafana-enterprise-logs-app'
)

const APP_ID: AppId = 'grafana-enterprise-logs-app';

export const PLUGIN_BASE_URL = `/a/${APP_ID}`;

export function isAppPlugin(id: AppId) {
  return `${APP_ID}` === id;
}
