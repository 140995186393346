import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { css, cx } from '@emotion/css';

import { selectByName as selectDatasourceByName, useCreateDatasource } from '@common/state/generatedDatasources';
import { AccessPolicy, Tenant, Token } from '@common/types';
import {
  BackendContext,
  generateDatasourceName,
  getCombinedTenantDisplayNames,
  getCombinedTenantNames,
  useUtilityStyles,
} from '@common/utils';

import { DetailedTooltip } from '../Utils';

import { DatasourceSettings } from './DatasourceSettings/DatasourceSettings';

type MultiTenantProps = {
  /** The access policy that we would like to create a data source for. */
  accessPolicy: AccessPolicy;
  /** Additional classnames for styling. */
  className?: string;
  /** The tenant that we would like to create a data source for. */
  tenants: Tenant[];
  /** The token that we would like to create a data source for. */
  token: Token;
};

type SingleTenantProps = {
  /** The access policy that we would like to create a data source for. */
  accessPolicy: AccessPolicy;
  /** Additional classnames for styling. */
  className?: string;
  /** The tenant that we would like to create a data source for. */
  tenant: Tenant;
  /** The token that we would like to create a data source for. */
  token: Token;
};

export const CreateDatasourceWidget: FunctionComponent<MultiTenantProps> = ({
  accessPolicy,
  className,
  tenants,
  token,
}) => {
  const s = useUtilityStyles();
  const cell = css`
    width: 100%;
    list-style: none;
  `;

  const {
    backend: { features },
  } = useContext(BackendContext);
  const federatedQueriesEnabled = !!features.federated_queries;

  return (
    <div className={cx(s.flex, s.alignCenter, s.marginTopXs, s.textSm, className)}>
      {/* If federated queries are enabled, the user can add multiple tenants to a datsource at once */}
      {federatedQueriesEnabled ? (
        <MultiDatasourceWidget accessPolicy={accessPolicy} tenants={tenants} token={token} />
      ) : (
        <ul className={cx(s.marginRightMd, s.textBold, cell)}>
          {/* Otherwise, the user can add only one tenant per data source
          but they can all use the same token. So we check here for productName, and then require
          that user add tenants individually to data source
          */}
          {tenants.map((tn) => {
            return <SingleDatasourceWidget key={tn.name} tenant={tn} accessPolicy={accessPolicy} token={token} />;
          })}
        </ul>
      )}
    </div>
  );
};

const MultiDatasourceWidget: FunctionComponent<MultiTenantProps> = ({ accessPolicy, tenants, token }) => {
  // Note: CreateDatasource may be `null` for a brief period of time
  //       while ensuring the function has access to http_api_prefix.
  //       The associated button will be disabled accordingly.
  const createDatasource = useCreateDatasource();
  const combinedTenantDisplayNames = getCombinedTenantDisplayNames(tenants);
  const combinedAuthUserNames = getCombinedTenantNames(tenants);
  const name = generateDatasourceName(accessPolicy.display_name, combinedAuthUserNames, token.display_name);
  const datasource = useSelector(selectDatasourceByName(name));
  const isCreatedAlready = Boolean(datasource);
  const basicAuthPassword = token?.token || '';
  const s = useUtilityStyles();
  const list = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  return (
    <div key={name} className={cx(s.marginTopSm, list)}>
      <span className={cx(s.marginRightMd, s.textBold)}>
        <DetailedTooltip label={combinedTenantDisplayNames} maxWidth={s.maxWidth300} />
      </span>
      <DatasourceSettings
        name={name}
        basicAuthUser={combinedAuthUserNames}
        basicAuthPassword={basicAuthPassword}
        isCreatedAlready={isCreatedAlready}
        datasource={datasource}
        createDatasource={createDatasource}
      />
    </div>
  );
};

const SingleDatasourceWidget: FunctionComponent<SingleTenantProps> = ({ accessPolicy, tenant, token }) => {
  // Note: CreateDatasource may be `null` for a brief period of time
  //       while ensuring the function has access to http_api_prefix.
  //       The associated button will be disabled accordingly.
  const createDatasource = useCreateDatasource();
  const name = generateDatasourceName(accessPolicy.display_name, tenant.name, token.display_name);
  const s = useUtilityStyles();
  const datasource = useSelector(selectDatasourceByName(name));
  const isCreatedAlready = Boolean(datasource);
  const basicAuthPassword = token?.token || '';
  const list = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `;

  return (
    <li key={tenant.name} className={cx(s.marginTopSm, list)}>
      <span
        className={cx(
          s.marginBottomSm,
          css`
            margin-top: 2px;
          `
        )}
      >
        <DetailedTooltip label={tenant.display_name} maxWidth={s.maxWidth300} />
      </span>
      <DatasourceSettings
        name={name}
        basicAuthUser={tenant.name}
        basicAuthPassword={basicAuthPassword}
        isCreatedAlready={isCreatedAlready}
        datasource={datasource}
        createDatasource={createDatasource}
      />
    </li>
  );
};
