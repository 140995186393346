import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Field, Input, useStyles2 } from '@grafana/ui';

import { SelectCluster, TenantLimits } from '@common/components';
import { useCreateLoading, useUpdateLoading } from '@common/state/tenants';
import { Tenant } from '@common/types';
import { BackendContext, generateName } from '@common/utils';

import { Collapse } from '../Collapse';

import { getStyles } from './TenantForm.styles';

type Props = {
  /** (Optional) Additional styling for the form */
  className?: string;
  /** A tenant object that can be used to pre-fill the form. Can be used for editing tenants. */
  defaultValues?: Partial<Tenant>;
  nameValidator?: (tenantName: string) => string | undefined;
  /** A callback called when the "Cancel" button is clicked. */
  onCancel: () => void;

  /** A callback called when the form is submitted. */
  onSubmit: (data: Tenant) => void;
};

export const TenantForm = ({ className, defaultValues, nameValidator, onCancel, onSubmit }: Props) => {
  const styles = useStyles2(getStyles(className));

  const {
    backend: {
      implicitFeatures: { tenantCustomizableLimits },
    },
  } = useContext(BackendContext);
  const isTenantLimitsEnabled = !!tenantCustomizableLimits.length;
  const [limits, setLimits] = useState(defaultValues?.limits || {});
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setFocus,
    setValue,
    watch,
  } = useForm<Tenant>({ defaultValues });
  const isCreating = useCreateLoading();
  const isSaving = useUpdateLoading();
  const isEditMode = Boolean(defaultValues?.name);
  const submitButtonText = isEditMode
    ? isSaving
      ? 'Saving...'
      : 'Save changes'
    : isCreating
    ? 'Creating...'
    : 'Create tenant';
  const [prevDisplayName, setPrevDisplayName] = useState(defaultValues?.display_name || '');
  const watchDisplayName = watch('display_name', '');
  const watchName = watch('name', '');

  useEffect(() => {
    const displayNameHasChanged = watchDisplayName !== prevDisplayName;
    const nameWasAutoGenerated = generateName(prevDisplayName) === watchName;
    const nameCanBeAutoGenerated = (nameWasAutoGenerated || watchName === '') && !isEditMode;

    if (displayNameHasChanged && nameCanBeAutoGenerated) {
      setValue('name', generateName(watchDisplayName || ''));
    }

    setPrevDisplayName(watchDisplayName || '');
  }, [watchName, watchDisplayName, prevDisplayName, setValue, isEditMode]);

  useEffect(() => {
    setFocus('display_name');
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ ...data, limits }))} className={styles.form}>
      {/* Display Name */}
      <Field label="Display name" invalid={!!errors.display_name} error="Display name is required">
        <>
          <Input {...register('display_name', { required: true })} name="display_name" placeholder="" />
          <div className={styles.description}>The name that you are going to see when looking through tenants.</div>
        </>
      </Field>

      {/* Name */}
      <Field label="Name" invalid={!!errors.name} error={errors.name?.message || 'Name is required'}>
        <>
          <Input
            {...register('name', {
              required: true,
              validate: nameValidator,
            })}
            name="name"
            placeholder=""
            disabled={isEditMode} // The name property is not editable.
          />
          <div className={styles.description}>Used as a unique identifier for the tenant.</div>
        </>
      </Field>

      {/* Cluster */}
      <Field label="Cluster" invalid={!!errors.cluster} error="Cluster is required">
        <>
          <SelectCluster name="cluster" control={control} required />
          <div className={styles.description}>The cluster the tenant belongs to.</div>
        </>
      </Field>

      {/* Limits */}
      {isTenantLimitsEnabled && (
        <Collapse
          title={<span className={styles.limitSettingsTitle}>Limits settings</span>}
          subTitle="Expand to specify custom limits for the tenant."
          defaultIsOpen={isEditMode}
          className={styles.collapse}
        >
          <TenantLimits onChange={setLimits} defaultValues={defaultValues?.limits} />
        </Collapse>
      )}

      {/* Actions */}
      <div className={styles.actionsContainer}>
        <Button
          className={styles.submitButton}
          type="submit"
          disabled={isCreating || isSaving}
          icon={isCreating || isSaving ? 'fa fa-spinner' : undefined}
        >
          {submitButtonText}
        </Button>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </div>
    </form>
  );
};
