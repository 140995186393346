import React from 'react';
import { useDispatch } from 'react-redux';

import { create } from '@common/state/tenants';
import { Tenant } from '@common/types';

import { CreateTenantModal } from './CreateTenantModal';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const CreateTenantModalContainer = ({ isOpen, onDismiss }: Props) => {
  const dispatch = useDispatch();
  const onCreate = (tenant: Tenant) => dispatch(create(tenant));

  return <CreateTenantModal isOpen={isOpen} onDismiss={onDismiss} onCreate={onCreate} />;
};
