import React from 'react';

import { AppPluginMeta } from '@grafana/data';

import { getPluginLogo } from '@common/utils';
import { css, cx } from '@emotion/css';

type Props = {
  title?: string;
  name?: string;
  meta: AppPluginMeta;
};

export const Header = ({ title, name, meta }: Props) => {
  return (
    <>
      <span className="page-header__logo" >
        <img className={css`height: 50px`} src={getPluginLogo(meta)} alt={`logo of ${name}`} />
      </span>
      <div className="page-header__info-block">
        <h1 className={cx('page-header__title', css`padding-top: 0px;`)}>{name}</h1>
        <div className="page-header__sub-title">{title}</div>
      </div>
    </>
  );
};
