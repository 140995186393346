import { parse } from 'date-fns';
import moment from 'moment'; // eslint-disable-line no-restricted-imports

import { dateTime, dateTimeFormatTimeAgo } from '@grafana/data';
export { parseISO as parseISODate, format as formatDate } from 'date-fns';

export const isDateNowOrLater = (date?: string | Date) => {
  if (date) {
    const parsedDate = new Date(date);

    if (parsedDate.getTime() >= Date.now()) {
      return true;
    }
  }
  return false;
};

export const timeAgo = (date?: string | Date) => {
  if (date) {
    const parsedDate = new Date(date);

    return dateTimeFormatTimeAgo(parsedDate);
  }

  return '-';
};

export const sortByDateField = (items: any[], field: string) => {
  // If the field is empty, we put the item to the end of the list
  const MIN_DATE = new Date(-8640000000000000);

  return items.sort((a, b) => {
    const aDate = new Date(a[field] || MIN_DATE);
    const bDate = new Date(b[field] || MIN_DATE);

    return bDate.getTime() - aDate.getTime();
  });
};

export const getDateForDaysLater = (daysLater: number) => {
  // Take everything on the left side of ISO date before the T.
  // e.g, 2022-02-16T22:24:21.480Z
  const [date] = dateTime().add(daysLater, 'days').toISOString().split('T', 1);
  return date;
};

export const fixMimirDate = (cortexDate: string) => {
  // TODO: Fix this in Mimir
  // Unfortunately the "timestamp" field is not in an RFC3339 format, so it cannot be parsed by the javascript easily.
  // This is due to just using the Time.String function in Go, which is not returning a stable serialized representation.
  // Code in Cortex: https://github.com/cortexproject/cortex/blob/master/pkg/ring/http.go#L164
  // Note that this is not an issue in Tempo or Loki.
  return parse(cortexDate.replace('UTC', ''), 'yyyy-MM-dd HH:mm:ss xxxx', new Date(Date.now()));

  // EXAMPLES:
  // From LOGS does not require fixing:
  //  2022-04-29T22:02:18Z
  // From METRICS, needs fixing:
  //  2022-04-29 22:01:46 +0000 UTC
};

export function isValidDate(date: undefined | Date | string): date is Date | string {
  if (date === undefined) {
    return false;
  }
  return !Number.isNaN(new Date(date).getTime());
}

/**
 * Calculate the numbers of days in between two dates. Starting date will set to today
 * if it is undefined
 * @param {boolean} include to determine whether to include the starting date
 * @param {string | Date} targetDate ending date
 * @param {string | Date} fromDate starting date
 * @returns numbers of days
 */
export const daysBetween = (include: boolean, targetDate: string | Date, fromDate?: string | Date): number => {
  if (!fromDate || fromDate === '') {
    const today = moment();

    const result = moment(targetDate).diff(today, 'days');

    // calculate the days between today and selected date including the start
    return include ? result + 1 : result;
  } else {
    const result = moment(targetDate).diff(fromDate, 'days');

    return include ? result + 1 : result;
  }
};
