import React, { useState } from 'react';
import { cx } from '@emotion/css';

import { ConfirmModal, Drawer } from '@grafana/ui';

import {
  AdminPageActionsBar,
  AccessPoliciesListItem,
  AccessPolicyForm,
  ApiError,
  CreateAccessPolicyInfoBox,
  CreateAccessPolicyModal,
  CreateTokenModal,
  LoadingIndicator,
} from '@common/components';
import {
  useDeactivate,
  useFetchById,
  useGetAll as useGetAllAccessPolicies,
  useUpdate,
} from '@common/state/accessPolicies';
import { useIsLoading } from '@common/state/requests';
import { useGetAll as useGetAllTenants } from '@common/state/tenants';
import { useGetAll as useGetAllTokens } from '@common/state/tokens';
import { AccessPolicy } from '@common/types';
import {
  getLimitedLengthName,
  popAlert,
  sortAccessPolicyByLatestFirst,
  timeAgo,
  useUtilityStyles,
} from '@common/utils';

import t from './text';

export const AccessPolicies = () => {
  useGetAllTokens(true);
  useGetAllTenants(true);

  const s = useUtilityStyles();
  const { activeResources: items, isError, isLoading } = useGetAllAccessPolicies();
  const deactivate = useDeactivate();
  const onUpdate = useUpdate();
  const fetchSingle = useFetchById();
  const isLoadingDetails = useIsLoading('accesspolicies/fetchById');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [accessPolicyForNewToken, setAccessPolicyForNewToken] = useState<AccessPolicy>();
  const [selectedItem, setSelectedItem] = useState<AccessPolicy>();
  const [itemToBeDeleted, setItemToBeDeleted] = useState<AccessPolicy>();
  // We would only like to show the loading indicator for the list before the initial fetch.
  // (The state would end up in a loading state even when we are creating / deleting items,
  // however we wouldn't like the list to flicker when this is happening.)
  const shouldShowLoading = isLoading && !items.length;
  const hasNoItems = !isLoading && items.length === 0;
  const hasItems = items.length > 0;

  if (isError) {
    return <ApiError actionType="accessPolicies/fetchAll" />;
  }

  return (
    <div>
      {/* Modal - Create Token Policy */}
      {accessPolicyForNewToken && (
        <CreateTokenModal
          onDismiss={() => setAccessPolicyForNewToken(undefined)}
          accessPolicy={accessPolicyForNewToken}
          isOpen
        />
      )}

      {/* Modal - Delete Confirmation Modal */}
      {itemToBeDeleted && (
        <ConfirmModal
          isOpen
          icon="trash-alt"
          title={t.deleteConfirmTitle}
          confirmText={t.deleteConfirmTitle}
          body={<div className={s.textMd}>{t.deleteConfirmMessage(itemToBeDeleted.display_name)}</div>}
          onDismiss={() => setItemToBeDeleted(undefined)}
          onConfirm={() => {
            deactivate(itemToBeDeleted);
            setItemToBeDeleted(undefined);
          }}
        />
      )}

      {/* Modal - Create Access Policy */}
      <CreateAccessPolicyModal isOpen={isCreateModalOpen} onDismiss={() => setIsCreateModalOpen(false)} />

      {/* Top actions bar */}
      {hasItems && <AdminPageActionsBar createType="access policy" onCreate={() => setIsCreateModalOpen(true)} />}

      {/* Loading indicator */}
      {shouldShowLoading && <LoadingIndicator />}

      {/* No items */}
      {hasNoItems && <CreateAccessPolicyInfoBox onCreate={() => setIsCreateModalOpen(true)} />}

      {/* List access policies */}
      {hasItems &&
        sortAccessPolicyByLatestFirst(items).map((item: AccessPolicy) => (
          <AccessPoliciesListItem
            key={item.name}
            item={item}
            onDelete={setItemToBeDeleted}
            onCreateToken={setAccessPolicyForNewToken}
            onEdit={() => {
              // We are fetching the access policy to get the latest changes & version before starting to edit
              fetchSingle(item.name);
              setSelectedItem(item);
            }}
          />
        ))}

      {/* Item details */}
      {selectedItem && (
        <Drawer
          expandable
          scrollableContent
          // TODO This is workaround, eventually we want to fix this in the grafana repo
          title={getLimitedLengthName(selectedItem)}
          subtitle={`Created ${timeAgo(selectedItem.created_at)}`}
          onClose={() => setSelectedItem(undefined)}
          width="40%"
        >
          {isLoadingDetails && <LoadingIndicator />}

          {/* Displaying as an editable form */}
          {!isLoadingDetails && (
            <AccessPolicyForm
              defaultValues={selectedItem}
              className={cx(s.marginXAuto, s.marginTopMd, s.widthFull, s.maxWidth700)}
              onCancel={() => setSelectedItem(undefined)}
              onSubmit={(data: AccessPolicy) => {
                onUpdate({
                  ...selectedItem,
                  ...data,
                  // The name cannot be updated
                  name: selectedItem.name,
                })
                  // @ts-ignore - AsyncThunk actually returns a promise
                  .then(() => {
                    setSelectedItem(undefined);

                    popAlert('policy', 'updated', data.display_name);
                  });
              }}
            />
          )}
        </Drawer>
      )}
    </div>
  );
};
