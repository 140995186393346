import { recommendRefresh } from './url';

export const isBrowserShowingDashboardUrl = () => window.location.pathname.startsWith('/d/');

export const isBrowserShowingManageDashboardsUrl = () => window.location.pathname.startsWith('/dashboards');

export const refreshDashboardsAndFolders = () => {
  if (isBrowserShowingManageDashboardsUrl()) {
    recommendRefresh('Dashboards have been updated.');
  }
};
