import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal, useStyles2 } from '@grafana/ui';

import { useGetAll as useGetAllClusters } from '@common/state/clusters';
import { useGetAll } from '@common/state/tenants';
import { Tenant } from '@common/types';
import { popAlert } from '@common/utils';

import { ApiError } from '../ApiError';
import { TenantForm } from '../TenantForm';

import { getStyles } from './CreateTenantModal.styles';

type Props = {
  isOpen: boolean;
  onCreate: (tenant: Tenant) => any;
  onDismiss: () => void;
};

export const CreateTenantModal = ({ isOpen, onCreate, onDismiss }: Props) => {
  const styles = useStyles2(getStyles);

  const clusters = useGetAllClusters();
  const { entityDictionary: tenantsByName } = useGetAll();

  return (
    <Modal
      contentClassName={styles.modal}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onClickBackdrop={onDismiss}
      title={<div className={styles.title}>Create new tenant</div>}
    >
      <ApiError actionType="tenants/create" />

      <TenantForm
        defaultValues={{ cluster: clusters[0]?.name }}
        onCancel={onDismiss}
        nameValidator={(tenantName) => {
          const existingTenant = tenantsByName[tenantName];
          switch (existingTenant?.status) {
            case 'active':
              return 'You are already using this tenant name. Try using a different one.';
            case 'inactive':
              return 'This name was used for a tenant that has been deactivated. Try using a new name.';
          }
          return undefined;
        }}
        onSubmit={(data) => {
          // Limits must be left undefined if there are no keys. Sending `{}` means use admin-api default limits.
          // See:
          // - https://github.com/grafana/gex-plugins/issues/375
          // - https://github.com/grafana/gex-plugins/issues/698
          // ...for details
          if (data.limits && Object.keys(data.limits).length === 0) {
            data.limits = undefined;
          }

          onCreate({
            ...data,
            created_at: new Date(Date.now()).toISOString(),
          })
            .then(unwrapResult)
            .then(() => {
              onDismiss();

              popAlert('tenant', 'created', data.display_name);
            });
        }}
      />
    </Modal>
  );
};
