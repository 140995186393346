import React, { useContext, useState } from 'react';
import { AsyncThunkAction } from '@reduxjs/toolkit';

import { dateTimeFormatTimeAgo } from '@grafana/data';
import { ConfirmModal, HorizontalGroup, Icon, IconButton, useStyles2 } from '@grafana/ui';

import { useLimitOverrideState } from '@common/state/cortex';
import { Tenant, TenantStats } from '@common/types';
import {
  BackendContext,
  formatIngestionRate,
  formatThousands,
  isArray,
  isReservedTenant,
  stringifyLimit,
} from '@common/utils';

import { Collapse } from '../Collapse';
import { TenantStatistic as TenantStatisticComponent } from '../TenantStatistic';
import { TenantStatusDot } from '../TenantStatusDot';

import { getStyles } from './TenantListItem.styles';

type Props = {
  /** The tenant object that is going to be displayed. */
  item: Tenant;
  /** A callback function called when the user clicks on the delete tenant button. */
  onDelete: (tenant: Tenant) => AsyncThunkAction<Tenant, Tenant, {}>;
  /** (Optional) A callback function called when the user clicks on the tenant row. */
  onEdit?: (item: Tenant) => void;
  /** The stats objects associated with this tenant (if present) */
  stats?: TenantStats;
};

export const TenantListItem = ({ item, onDelete, onEdit, stats }: Props) => {
  const [deleteItem, setDeleteItem] = useState<Tenant>();
  const styles = useStyles2(getStyles);

  const {
    backend: {
      implicitFeatures: { tenantCustomizableLimits, tenantStatsEnabled },
    },
  } = useContext(BackendContext);

  const { overriddenLimits } = useLimitOverrideState(item.limits);
  const overriddenLimitsCount = Object.keys(overriddenLimits).length;
  const showOverriddenLimits = !!(tenantCustomizableLimits.length && overriddenLimitsCount);
  const isReserved = isReservedTenant(item);

  const edit = !isReserved && onEdit ? () => onEdit(item) : undefined;
  return (
    <>
      <div className={styles.container} data-testid={`tenant-${item.name}`}>
        <div className={styles.header}>
          {/* Status */}
          <div className={styles.tenantStatusDot}>
            <TenantStatusDot status={item.status} />
          </div>

          {/* Display Name */}
          <div className={styles.namesContainer}>
            <span>{item.display_name}</span>
            <div className={styles.tenantName}>{item.name}</div>
          </div>

          {
            /* Created At & Cluster  */
            !isReserved && (
              <div className={styles.infoContainer}>
                <div title={item.created_at}>
                  <Icon name="clock-nine" className={styles.timeAndClusterInfo} />
                  {item.created_at ? dateTimeFormatTimeAgo(new Date(item.created_at)) : '-'}
                </div>
                <div>
                  <Icon name="cloud" className={styles.timeAndClusterInfo} />
                  {item.cluster || '-'}
                </div>
              </div>
            )
          }

          {
            /* Actions */
            !isReserved && (
              <div className={styles.actionContainer}>
                <HorizontalGroup>
                  <IconButton
                    aria-label='Edit'
                    className={styles.editIcon}
                    data-testid="tenant-edit"
                    name="edit"
                    size="lg"
                    disabled={!edit}
                    onClick={edit}
                  />
                  <IconButton
                    aria-label='Delete'
                    className={styles.deleteIcon}
                    name="trash-alt"
                    data-testid="tenant-delete"
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteItem(item);
                    }}
                  />
                </HorizontalGroup>
              </div>
            )
          }
        </div>

        {/* Stats */}
        {tenantStatsEnabled && !stats && <div className={styles.noStat}>No statistics available.</div>}
        {stats && (
          <div className={styles.statContainer}>
            <TenantStatisticComponent
              className={styles.defaultIngestionRate}
              label="Ingestion rate"
              stat={formatIngestionRate(stats.ingestionRate)}
            />
            <TenantStatisticComponent
              className={styles.ingestionRate}
              label="API ingestion rate"
              stat={formatIngestionRate(stats.apiIngestionRate)}
            />
            <TenantStatisticComponent
              className={styles.ingestionRate}
              label="Rule ingestion rate"
              stat={formatIngestionRate(stats.ruleIngestionRate)}
            />
            <TenantStatisticComponent
              className={styles.ingestionRate}
              label="Number of series"
              stat={formatThousands(stats.numberOfSeries)}
            />
          </div>
        )}

        {/* Limits */}
        {showOverriddenLimits && (
          <Collapse
            className={styles.collapse}
            title={`${overriddenLimitsCount} custom limit${overriddenLimitsCount > 1 ? 's' : ''}`}
            defaultIsOpen={false}
          >
            <div className={styles.limitContainer}>
              {Object.keys(overriddenLimits).map((name) => (
                <div key={name} className={styles.limitNames}>
                  <span>{name}:</span>

                  {/* Array limit value type */}
                  {isArray(overriddenLimits[name]) &&
                    overriddenLimits[name].map((limit: string) => (
                      <span key={limit} className={styles.limit}>
                        {limit}
                      </span>
                    ))}

                  {/* Other limit value type */}
                  {!isArray(overriddenLimits[name]) && (
                    <span className={styles.overriddenLImits}>{stringifyLimit(overriddenLimits[name])}</span>
                  )}
                </div>
              ))}
            </div>
          </Collapse>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {deleteItem && (
        <ConfirmModal
          isOpen
          icon="trash-alt"
          title="Delete tenant"
          body={
            <div className={styles.deleteModal}>
              Are you sure you want to delete &quot;{deleteItem.display_name}&quot;?
            </div>
          }
          confirmText="Delete"
          onDismiss={() => setDeleteItem(undefined)}
          onConfirm={() => {
            onDelete(deleteItem);
            setDeleteItem(undefined);
          }}
        />
      )}
    </>
  );
};
