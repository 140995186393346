import { addTenantStatsEnricher, getPlugin } from '@common/api';
import { initDashboards } from './dashboards';
import { tenantActiveSeriesCountsEnricher } from './queries';
import { AppPluginSettings } from '@common/types';
import { waitForBackendSrv } from '@common/utils';

export function performGemPreload() {
  const preloadInit = async () => {
    addTenantStatsEnricher(tenantActiveSeriesCountsEnricher);
  
    // // Note -- if we were on a grafana-metrics-enterprise-app page,
    // // this will have already been fetched as part of setting up `plugin` above...
    const pluginMeta = await getPlugin<AppPluginSettings>('grafana-metrics-enterprise-app');
  
    initDashboards(pluginMeta);
  };
  
  waitForBackendSrv(preloadInit)
}

