import { AppPlugin } from '@grafana/data';

import { connectionSettingsConfigPageTab, getApp } from '@common/pages';
import { AppPluginSettings } from '@common/types';
import { isAppPlugin } from './constants';
import { performGemPreload } from '@gem/preload';


export const plugin = new AppPlugin<AppPluginSettings>();
plugin.setRootPage(getApp(plugin));
plugin.addConfigPage(connectionSettingsConfigPageTab);

if (isAppPlugin('grafana-metrics-enterprise-app')) {
  // Due to plugin.json setting "preload"="true", the following will be called immediately when webpage is loaded.
  performGemPreload();
}
