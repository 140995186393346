import { createSlice } from '@reduxjs/toolkit';

import { ApiPrefixes, changeApiPrefixesAction } from './apiPrefixes.actions';

const initialState: ApiPrefixes = {
  pluginPrefix: 'api/plugins/no-plugin-setup/resources',
  adminApiPrefix: 'api/plugins/no-plugin-setup/resources/admin/api/v3',
};

export const { reducer, actions, caseReducers } = createSlice({
  name: 'apiPrefixes',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(changeApiPrefixesAction, (state, action) => {
      state.adminApiPrefix = action.payload.adminApiPrefix;
      state.pluginPrefix = action.payload.pluginPrefix;
    }),
});
