import { AdminResource } from '@common/types';

import { LabelPolicy } from './AccessPolicy';
import { Limits } from './Cortex';

export const WILDCARD_TENANT_NAME = '*';

export type Tenant = AdminResource & {
  cluster?: string;
  label_policies?: LabelPolicy[];
  limits?: Limits;
};
