import { AdminResource } from '@common/types';

import { Scope } from './Scopes';
import { Token } from './Token';

// Using the string symbols for the types, too, as they are more clear in this case than wording the type.
// (can be changed later based on the backend implementation)
export enum MatcherType {
  equal = 'EQ',
  match = 'RE',
  noMatch = 'NRE',
  notEqual = 'NEQ',
}

export type Matcher = {
  name: string;
  type: MatcherType;
  value: string;
};

export type LabelPolicy = {
  // This property is not present in the backend data model, however it can be useful in the frontend for being
  // able to refer to a certain label policy properly on the UI.
  id?: string;
  // A list of label matchers as a string in the following format: "{env=\"dev\"}"
  selector: string;
};

export type Realm = {
  cluster?: string;
  label_policies?: LabelPolicy[];
  tenant?: string;
};

export type AccessPolicy = AdminResource & {
  realms: Realm[];
  scopes?: Scope[];
  tokens?: Token[];
};
