import { PLUGIN_BASE_URL } from '@common/constants';
import { ADMIN_RESOURCES_PLURAL } from '@common/types';
import { capitalize } from '@common/utils';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import React, { ReactNode, useCallback } from 'react';
import { useLocation } from 'react-router';

const pageTitles = {
  tenants: capitalize(ADMIN_RESOURCES_PLURAL.tenants),
  "access-policies": capitalize(ADMIN_RESOURCES_PLURAL.accesspolicies),
  "ring-health": "Ring health",
  licenses: capitalize(ADMIN_RESOURCES_PLURAL.licenses),
  'cluster-configuration': "Cluster config",
}

type Page = keyof typeof pageTitles;

const adminPages: Page[] = ['tenants', 'access-policies'];
const otherPages: Page[] = ['ring-health', 'licenses', 'cluster-configuration'];

export function ActionNav() {
  const location = useLocation();

  const current = location.pathname
    .split(PLUGIN_BASE_URL).at(-1) // Get the portion after the BASE URL
    ?.split('/')[1] // And extract the first path element after the first `/` (ignoring all subsequents)

  const styles = useStyles2(getStyles)

  const renderPageLink = useCallback((page: Page) => {

    const title = pageTitles[page]

    let inner: ReactNode = title;
    if (page !== current) {
      inner = <a href={`${PLUGIN_BASE_URL}/${page}`}>{title}</a>
    }

    return <span key={page}>{inner}</span>
  }, [current])

  return <>
    <div className={styles.row}>
      {adminPages.map(renderPageLink)}
    </div>
    <div className={styles.row}>
      {otherPages.map(renderPageLink)}
    </div>
  </>
}


function getStyles(theme: GrafanaTheme2) {

  const borderColor = theme.colors.emphasize(theme.colors.secondary.text, .6);

  return {
    row: css({
      ...theme.typography.bodySmall,
      alignItems: 'center',
      '& :not(:last-child)': {
        borderRight: `1px solid ${borderColor}`,
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      "& a": {
        color: theme.colors.text.link,
        textDecoration: 'underline'
      }, 
      "& :not(a)": {
        color: theme.colors.text.primary
      }
    }),
    
  };
}
