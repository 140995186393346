import React, { FunctionComponent, ReactNode } from 'react';
import { QueryClientProvider, useQuery } from '@tanstack/react-query';

import { AppPluginMeta, PluginConfigPage, PluginConfigPageProps } from '@grafana/data';
import { Alert } from '@grafana/ui';

import { BackendError, BackendFeaturesResponse, getBackendFeatures } from '@common/api';
import { BackendErrorWarning } from '@common/components';
import { queryClient, usePluginCache } from '@common/state';
import { AppPluginSettings, getAdminApiPrefix } from '@common/types';
import { BackendContext, getBackendContextObject, PluginMetaContext, usePluginMeta } from '@common/utils';

import { ConnectionSettings } from '../ConnectionSettings';

const BackendContextWrapper = ({ children }: { children: ReactNode }) => {
  const { enabled, jsonData, id: pluginId } = usePluginMeta().pluginMeta || {};

  const adminApiPrefix = pluginId ? getAdminApiPrefix(pluginId) : undefined;
  const { backendUrl } = jsonData || {};
  const { data: backend, error: backendError } = useQuery<BackendFeaturesResponse, BackendError>(
    [
      'features',
      backendUrl,
      adminApiPrefix,
      // when we consider multiple backends, we can add the current backend's identifier here
    ],
    () => {
      if (enabled && backendUrl) {
        return getBackendFeatures(adminApiPrefix);
      }
      return null;
    }
  );

  const context = getBackendContextObject(
    backend,
    adminApiPrefix,
    backendError?.message || backendError?.data?.message
  );

  return <BackendContext.Provider value={context}>{children}</BackendContext.Provider>;
};

export const ConnectionSettingsConfigPage: FunctionComponent<
  PluginConfigPageProps<AppPluginMeta<AppPluginSettings>>
> = ({ plugin }) => {
  const { pluginMeta, setPluginJsonData } = usePluginCache(plugin);
  const { enabled } = pluginMeta || {};

  // Wrap the children in the context
  return (
    <PluginMetaContext.Provider value={{ pluginMeta, setPluginJsonData, onConfigurationPage: true }}>
      <QueryClientProvider client={queryClient}>
        <BackendContextWrapper>
          {!enabled && (
            <Alert title={`This app plugin is not enabled`}>
              To use the <em>{plugin.meta.name}</em> app plugin, it must first be enabled.
              <div>
                The <em>enable</em> button can be found on the top right corner of this page.
              </div>
            </Alert>
          )}
          {enabled && (
            <>
              <BackendErrorWarning />
              <ConnectionSettings />
            </>
          )}
        </BackendContextWrapper>
      </QueryClientProvider>
    </PluginMetaContext.Provider>
  );
};

export const connectionSettingsConfigPageTab: PluginConfigPage<AppPluginMeta<AppPluginSettings>> = {
  body: ConnectionSettingsConfigPage,
  id: 'settings',
  title: 'Connection settings',
  icon: 'cog',
};
