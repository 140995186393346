import { getDataSourceSrv } from '@grafana/runtime';

import { isBrowserShowingDashboardUrl, recommendRefresh } from '@common/utils';

import { apiGet } from './api';

// This is a temporary work-around while waiting for this issue:
// https://github.com/grafana/grafana/issues/29809 "Plugins: Be able to reload data sources"
export const refreshDatasources = async () => {
  // Workaround based on https://github.com/grafana/grafana/issues/30728#issuecomment-769793659
  const response = await apiGet<any>('/api/frontend/settings');

  (getDataSourceSrv() as any).init(response.data.datasources);

  // If the data source page, or a dashboard page happens to be opened, it is best to refresh the whole page.
  // Otherwise, certain page elements will not be populated with the latest Datasources.
  if (isBrowserShowingDashboardUrl() || isBrowserShowingDatasourcesUrl()) {
    recommendRefresh('Datasources have been updated.');
  }
};

export const isBrowserShowingDatasourcesUrl = () => window.location.pathname.startsWith('/datasources');
