// The following versions are relative to the current supported release.
// This should be updated periodically, based on what is available here:
// - https://grafana.com/grafana/download

// The following need to be manually updated.
// When a new major version is released, we will wait until its first minor before
// incrementing `CURRENT_SUPPORTED_MAJOR`.

export const CURRENT_SUPPORTED_MAJOR = '11';
export const LATEST_MINOR_OF_PREVIOUS_MAJOR = '10.4.0';

export const CURRENT_SUPPORTED_MINOR = '11.2';
// Sometimes, bugs require that a later patch be considered the earliest supported release
export const EARLIEST_SUPPORTED_RELEASE_OF_PREVIOUS_MINOR = '11.1.0';

// The semver is a `||`-separated list of version ranges
export const SUPPORTED_VERSIONS = [
  `>= ${LATEST_MINOR_OF_PREVIOUS_MAJOR} < ${CURRENT_SUPPORTED_MAJOR}`,
  `>= ${EARLIEST_SUPPORTED_RELEASE_OF_PREVIOUS_MINOR}`,
].join(' || ');
