import * as React from 'react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';

import { AppPluginMeta, AppRootProps, GrafanaPlugin, OrgRole } from '@grafana/data';
import { config } from '@grafana/runtime';

import {
  BackendContextWrapper,
  ErrorScreenAccessDenied,
  ErrorScreenNoLicense,
  LoadingIndicator,
} from '@common/components';
import { PageRoutes } from '@common/pages';
import { persistor, queryClient, store, usePluginCache } from '@common/state';
import { AppPluginSettings } from '@common/types';
import { hasValidLicense, PluginMetaContext } from '@common/utils';

export const getApp = (plugin: GrafanaPlugin<AppPluginMeta<AppPluginSettings>>) => {
  const App = (props: AppRootProps<AppPluginSettings>) => {
    const { pluginMeta, setPluginJsonData } = usePluginCache<AppPluginSettings>(plugin);

    const licenseStatus = hasValidLicense();
    if (!licenseStatus.valid) {
      return <ErrorScreenNoLicense />;
    }

    // The plugin is currently only accessible for Admin users
    if (config.bootData?.user?.orgRole !== OrgRole.Admin) {
      return <ErrorScreenAccessDenied />;
    }

    if (!pluginMeta) {
      return <LoadingIndicator />;
    }

    return (
      <Provider store={store}>
        <PluginMetaContext.Provider value={{ pluginMeta, setPluginJsonData }}>
          <QueryClientProvider client={queryClient}>
            <BackendContextWrapper>
              <PersistGate loading={null} persistor={persistor}>
                <PageRoutes {...props} />
              </PersistGate>
            </BackendContextWrapper>
          </QueryClientProvider>
        </PluginMetaContext.Provider>
      </Provider>
    );
  };

  return App;
};
