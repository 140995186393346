import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button, Field, FieldValidationMessage, HorizontalGroup, Input, SecretInput } from '@grafana/ui';

type CommonConfig = {
  secureJsonData?: Record<string, string>;
  secureJsonFields: Record<string, boolean>;
  jsonData: Record<string, any>;
}

import {
  BackendContext,
  capitalize,
  getLicenseTokenError,
  prepareTokenForAdminApiUpdate,
  removeTrailingSlashes,
  usePluginMeta,
  useUtilityStyles,
} from '@common/utils';
import { Auth, convertLegacyAuthProps, ConfigSection } from '@grafana/plugin-ui';
import { css } from '@emotion/css';
import { queryClient } from '@common/state';


export const ConnectionSettings = () => {
  const { pluginMeta, setPluginJsonData } = usePluginMeta();
  const { jsonData, id, name } = pluginMeta || {};
  const { backend } = useContext(BackendContext);
  const s = useUtilityStyles();

  const [backendUrl, setBackendUrl] = useState<string>('');
  const [newAccessToken, setNewAccessToken] = useState<string | undefined>();

  const [connectionError, setConnectionError] = useState<string>();
  const [tokenError, setTokenError] = useState<string>();

  const [backendIdentifier, setBackendIdentifier] = useState<string>();
  const [savingApiSettings, setSavingApiSettings] = useState(false);

  const secureJsonFields = pluginMeta?.secureJsonFields;

  // The type is cast to `any` because the `Config` object strictly expects DataSourceSettings
  // See: https://github.com/grafana/plugin-ui/pull/113
  const initialCommonConnectionConfig = useMemo(() => ({
    jsonData: jsonData || {},
    secureJsonData: {},
    secureJsonFields: secureJsonFields || {},
  } as unknown as any), [jsonData, secureJsonFields])

  const [commonConnectionConfig, setCommonConnectionConfig] = useState<CommonConfig>(initialCommonConnectionConfig);

  const reset = useCallback(() => {
    setBackendUrl(removeTrailingSlashes(jsonData?.backendUrl || ''));

    // Blank string indicates that we should let the user edit it.
    // Undefined means that it is already set and saved in secureJsonData.
    if (secureJsonFields) {
      if (secureJsonFields.base64EncodedAccessToken === true) {
        setNewAccessToken(undefined)
      } else {
        setNewAccessToken('')
      }
    }

    setCommonConnectionConfig(initialCommonConnectionConfig);

    setConnectionError(undefined);
    setTokenError(undefined);
  }, [jsonData, secureJsonFields, initialCommonConnectionConfig]);

  useEffect(() => {
    if (backend.backendError) {
      setBackendIdentifier('Cannot connect');
    } else if (!backend.isBackend) {
      setBackendIdentifier('Checking...');
    } else {
      setBackendIdentifier(`${backend.name} ${backend.version || '(version not reported)'}`);
    }
  }, [backend, setBackendIdentifier]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onTokenReset = () => setNewAccessToken('');

  useEffect(() => {
    if (newAccessToken === '' || !newAccessToken) {
      setTokenError(undefined);
    } else {
      setTokenError(getLicenseTokenError(newAccessToken));
    }
  }, [newAccessToken]);

  const onChangeBackendUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setBackendIdentifier(undefined); // Clear if user starts to modify
    setConnectionError(undefined);
    setBackendUrl(event.target.value.trim());
  };

  const onChangeToken = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setTokenError(getLicenseTokenError(value));
    setNewAccessToken(value);
  };

  const onSaveApiSettings = async () => {
    if (!backendUrl) {
      return;
    }
    const fixedUrl = removeTrailingSlashes(backendUrl?.trim());
    setBackendUrl(fixedUrl);
    setSavingApiSettings(true);

    if (id && setPluginJsonData) {
      // Gather TLS-related common connection config
      const jsonDataFromCommonConfig = Object.fromEntries([
        'serverName',
        'tlsAuth',
        'tlsAuthWithCACert',
        'tlsSkipVerify'
      ].map((key) => [key, commonConnectionConfig?.jsonData[key]]).filter(([_, value]) => value !== undefined));

      const secureJsonDataFromCommonConfig = Object.fromEntries([
        'tlsCACert',
        'tlsClientCert',
        'tlsClientKey'
      ].map((key) => [key, commonConnectionConfig?.secureJsonData?.[key]]).filter(([_, value]) => value !== undefined));

      // Collect all jsonData & secureJsonData and save to backend
      const jsonData = {
        ...jsonDataFromCommonConfig,
        backendUrl: fixedUrl,
      };

      const secureJsonData = {
        ...secureJsonDataFromCommonConfig,
        ...(newAccessToken === undefined
          ? {} // Don't change the token
          : { base64EncodedAccessToken: prepareTokenForAdminApiUpdate(newAccessToken) } // Set the new token
        )
      }
      await setPluginJsonData(jsonData, secureJsonData);
    }
    setSavingApiSettings(false);
    // Force reloading everything
    queryClient.clear();
  };

  const authProps: ReturnType<typeof convertLegacyAuthProps> = useMemo(() => {
    const props = convertLegacyAuthProps({
      config: (commonConnectionConfig || {}) as any, onChange: (config: CommonConfig) => {
        setCommonConnectionConfig({
          ...config,
          jsonData: { ...config.jsonData }
        })
      }
    });
    // Hide the custom headers section
    props.customHeaders = undefined;
    // Reduce the authentication section
    props.visibleMethods = ['custom-token'];
    return props;
  }, [commonConnectionConfig])

  const customAuthMethods: (typeof authProps)['customMethods'] = [
    {
      component: <>    <SecretInput
        className={s.width500}
        id="base64EncodedAccessToken"
        data-testid="access-token"
        value={newAccessToken}
        placeholder={'Paste your token'}
        onChange={onChangeToken}
        disabled={secureJsonFields === undefined}
        isConfigured={newAccessToken === undefined}
        onReset={onTokenReset}
      />
        {tokenError && (
          <FieldValidationMessage className={s.marginTopSm}>{capitalize(tokenError)}</FieldValidationMessage>
        )}


      </>,
      id: 'custom-token',
      description: `The access token is a string of format "<name>:<password>" that has been base64
      encoded. It is saved on the server.`,
      label: "Access token"
    }

  ]

  return (
    <div className="gf-form-group">
      {/* API SETTINGS */}
      <div>
        <ConfigSection title='Connection'>
          <Field label={`${name} URL`} description="" className={s.marginTopMd}>
            <>
              <Input
                disabled={savingApiSettings}
                className={s.maxWidth600}
                id="backendUrl"
                data-testid="backend-url"
                label={`${name} URL`}
                value={backendUrl}
                placeholder={`E.g.: http://${id?.split('-').slice(1, 3).join('-')}`}
                onChange={onChangeBackendUrl}
                suffix={backendIdentifier}
              />
              {connectionError && (
                <FieldValidationMessage className={s.marginTopSm}>{capitalize(connectionError)}</FieldValidationMessage>
              )}
            </>
          </Field>

        </ConfigSection>

        <div className={
          css`
              margin-bottom: 16px;
            `}
        >
          <Auth
            {...authProps}
            customMethods={customAuthMethods}
          />
        </div>

        <HorizontalGroup className={s.marginTopMd}>
          <Button
            data-testid="test-and-save"
            type="submit"
            onClick={onSaveApiSettings}
            icon={savingApiSettings ? 'fa fa-spinner' : 'save'}
            // We would like to disable the submit button if the token is being edited but is either empty or incorrectly formatted.
            disabled={
              Boolean(
                !backendUrl ||
                newAccessToken === '' ||
                tokenError
              ) || savingApiSettings
            }
          >
            Test and save configuration
          </Button>
          <Button type="reset" onClick={reset} variant="destructive">
            Reset
          </Button>
        </HorizontalGroup>
      </div>
    </div>
  );
};
