import { OrgRole, PluginMeta } from '@grafana/data';
import { config } from '@grafana/runtime';

import {
  createNewDashboardFolder,
  DashboardSearchResult,
  deleteDashboardFolder,
  getPluginDashboards,
  importDashboard,
  ImportDashboardRequest,
  moveDashboard,
  searchDashboardsAndFolders,
  updatePlugin,
} from '@common/api';
import { AppPluginSettings } from '@common/types';
import { refreshDashboardsAndFolders, refreshDatasources, slugifyForUrl } from '@common/utils';

import selfMonitoringDashboardConfig from './dashboards/config.json';
import { initCardinalityDatasource, initSystemMonitoringDatasource } from './datasources';

const SYSTEM_MONITORING_FOLDER_NAME = selfMonitoringDashboardConfig.folder;
const CARDINALITY_FOLDER_NAME = 'GEM cardinality management';

const getDashboardFolder = async (name: string, dashboardTag: string, pathPrefix: string) => ({
  uid: slugifyForUrl(name),
  name,
  intendedContents: await searchDashboardsAndFolders({ tag: [dashboardTag] }),
  pathPrefix,
});

export const initDashboards = async (pluginMeta: PluginMeta<AppPluginSettings>) => {
  const { orgRole } = config.bootData.user;
  if (orgRole !== OrgRole.Admin) {
    // If the user doesn't have Admin OrgRole enabled, we skip this.
    // We only check orgRole, because in some environments, it's possible for to have Admin role
    // without being logged in as a specific user, using this arguments:
    //  - GF_AUTH_ANONYMOUS_ENABLED=true
    //  - GF_AUTH_ANONYMOUS_ORG_ROLE=Admin
    return;
  }

  // If a new plugin version is detected, we must disable/enable it
  // to ensure that the newest dashboards are loaded.
  const metadata = pluginMeta;

  const pluginId = metadata.id;

  if (pluginId === undefined) {
    throw Error('Unexpected error: cannot obtain the pluginId?');
  }

  // Find if folder already exists
  const commonDashboardNamePrefix = 'GEM ';
  const gemFolders = await searchDashboardsAndFolders({ query: commonDashboardNamePrefix, type: 'dash-folder' });

  const existingFoldersByUid = new Map<string, DashboardSearchResult>(gemFolders.map((result) => [result.uid, result]));

  if (!metadata.enabled) {
    // If plugin is disabled, delete folders created by previous initialization

    const existingFolders = [SYSTEM_MONITORING_FOLDER_NAME, CARDINALITY_FOLDER_NAME]
      .map((name) => existingFoldersByUid.get(slugifyForUrl(name)))
      .filter((folder) => folder !== undefined) as DashboardSearchResult[];

    const remainingDashboards = await searchDashboardsAndFolders({
      folderIds: existingFolders.map((folder) => folder.id),
      type: 'dash-db',
    });

    const folderIdsWithRemainingDashboards = new Set<number>(
      remainingDashboards.map((dashboard) => dashboard.folderId)
    );

    for (let existingFolder of existingFolders) {
      try {
        if (!folderIdsWithRemainingDashboards.has(existingFolder.id)) {
          await deleteDashboardFolder(existingFolder.uid, false);
        }
      } catch (err) {
        console.error(err);
      }
    }

    // Skip further initialization if the plugin isn't enabled.
    return;
  }

  // Otherwise,
  const dashboardFolders = await Promise.all(
    [
      { name: SYSTEM_MONITORING_FOLDER_NAME, pathPrefix: 'dashboards', tag: 'self-monitoring' },
      { name: CARDINALITY_FOLDER_NAME, pathPrefix: 'cardinality-dashboards', tag: 'cardinality-management' },
    ].map(async ({ name, pathPrefix, tag }) => await getDashboardFolder(name, tag, pathPrefix))
  );

  // Ensure all needed folders exist
  for (let folder of dashboardFolders) {
    try {
      if (!existingFoldersByUid.get(folder.uid)) {
        const result = await createNewDashboardFolder(folder.name);
        // Update the collection of existing folders
        existingFoldersByUid.set(result.uid, result);
      }
    } catch (err) {
      console.error(err);
    }
  }

  let anyDashboardsWereReimported = false;

  const { updated, version } = metadata.info || {};

  if (version && updated) {
    // These are expected to always be defined
    const installedVersion = `${version}--${updated}`;
    const initStatus = metadata.jsonData?.versionedInitStatus?.[installedVersion] || { reimportedDashboards: [] };

    const pluginDashboards = await getPluginDashboards(pluginId);

    // We will use the pathPrefix to help decide the folderId to import the dashboard into
    const pathPrefixToFolderId = new Map<string, number>();
    dashboardFolders.forEach(({ uid, pathPrefix }) => {
      const existingFolder = existingFoldersByUid.get(uid);
      if (existingFolder) {
        pathPrefixToFolderId.set(pathPrefix, existingFolder.id);
      }
    });

    // Filter for dashboards that will successfully import, and still need to be imported
    const reimportableDashboards = pluginDashboards.filter(
      ({ path, removed, pluginId }) =>
        // Only consider dashboards associated with our plugin
        pluginId === metadata.id &&
        // Avoid error when path is not correctly defined (a.k.a. "")
        // See https://github.com/grafana/support-escalations/issues/4938#issuecomment-1421580180
        !!path &&
        // ...or if the dashboard is meant to be considered "removed"
        // See https://github.com/grafana/gex-plugins/issues/946#issuecomment-1422739479
        !removed &&
        // Avoid importing dashboards if they've already been reimported
        !initStatus.reimportedDashboards.includes(path)
    );

    // Ensure that these "reimport" operations are performed one-after-another

    // First, define the operation
    const reimportDashboardOperation = async (path: string) => {
      // If there is an existing folder, try to import into it by its folderId.
      // This is a workaround for https://github.com/grafana/gex-plugins/issues/616
      const [pathPrefix] = path.split('/', 1);
      const folderId = pathPrefixToFolderId.get(pathPrefix);

      const importRequest: ImportDashboardRequest = {
        folderId,
        inputs: [],
        overwrite: true,
        path,
        pluginId,
      };

      try {
        await importDashboard(importRequest);
        initStatus.reimportedDashboards.push(path);
        const jsonData = {
          ...metadata.jsonData,
          versionedInitStatus: {
            ...metadata.jsonData?.versionedInitStatus,
            [installedVersion]: initStatus,
          },
        };
        anyDashboardsWereReimported = true;
        await updatePlugin(pluginId, {
          enabled: true,
          jsonData,
          pinned: true,
        });
      } catch (error) {
        console.log('initDashboards encountered error while re-importing plugin dashboards', path, error);
        console.error(error);
      }
    };

    // Then iterate over paths of the dashboards, to import them one by one
    for (const { path } of reimportableDashboards) {
      await reimportDashboardOperation(path);
    }
  }

  let anyDashboardsWereMoved = false;

  for (let folder of dashboardFolders) {
    try {
      const existingFolder = existingFoldersByUid.get(folder.uid) ?? (await createNewDashboardFolder(folder.name));

      // Determine appropriate dashboards that are not already in the target folder...
      const dashboardsToMove = folder.intendedContents.filter(
        (result) =>
          result.folderUid !== folder.uid && // Only move dashboards that aren't already in the target folder
          !result.tags.includes('grafanacloud') // ...but not the ones that are already provisioned by grafanacloud.
      );

      // ...and move them to the target folder.
      for (const dashboard of dashboardsToMove) {
        try {
          const moveResult = await moveDashboard(dashboard.uid, existingFolder.id);
          anyDashboardsWereMoved ||= moveResult;
        } catch (e) {
          // Sometimes the request to move the dashboard is aborted (cause unknown).
          // This seems to happen when a dashboard page is currently open.
          // If this happens, we just want to carry on as usual, and not let it stop the overall procedure which follows.
        }
      }
    } catch (error) {
      // If the creation of the new dashboard folder fails for this user, we silently move on to the next folder.
    }
  }

  const createdCardinalityDatasource = await initCardinalityDatasource();
  const createdSystemMonitoringDatasource = await initSystemMonitoringDatasource(pluginMeta);

  if (createdCardinalityDatasource || createdSystemMonitoringDatasource) {
    refreshDatasources();
  }

  if (anyDashboardsWereMoved || anyDashboardsWereReimported) {
    refreshDashboardsAndFolders();
  }
};
