import React, { useContext, useState } from 'react';
import { css } from '@emotion/css';
import { unwrapResult } from '@reduxjs/toolkit';
import { difference as _difference, intersection as _intersection, some as _some } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, Field, Input, Modal, useStyles2 } from '@grafana/ui';

import { useTenantsForAccessPolicy } from '@common/state/tenants';
import { useGetAll } from '@common/state/tokens';
import { AccessPolicy, READ_SCOPE_BY_BACKEND_TYPE, Scope, Token } from '@common/types';
import { BackendContext, parseISODate, popAlert, tokenNameGenerator } from '@common/utils';

import { ApiError } from '../ApiError';
import { CopyToClipboard } from '../CopyToClipboard';
import { CreateDatasourceWidget } from '../CreateDatasourceWidget';
import { RemoteWriteConfigSnippet } from '../RemoteWriteConfigSnippet';

import { CreateForm, TokenFormData } from './CreateTokenModalForm';

type Props = {
  accessPolicy: AccessPolicy;
  clearSecrets: () => void;
  isOpen: boolean;
  onCreate: (key: Token) => any;
  onDismiss: () => void;
  tokenWithSecret?: Token;
};

export const CreateTokenModal = ({
  accessPolicy,
  clearSecrets,
  isOpen,
  onCreate,
  onDismiss,
  tokenWithSecret,
}: Props) => {
  const styles = useStyles2(getStyles);
  const [shouldShowApiError, setShouldShowApiError] = useState(false);
  const tenants = useTenantsForAccessPolicy(accessPolicy);
  const shouldShowForm = !tokenWithSecret;
  const title = !!tokenWithSecret ? 'Token successfully added!' : 'Create new token';
  const onDismissAndClear = () => {
    onDismiss();
    clearSecrets();
  };

  const {
    backend: {
      features,
      name,
      accessPolicyScopeFeatures: { defaultScopes },
    },
  } = useContext(BackendContext);

  const { entityDictionary: tokensByName } = useGetAll();

  const backendReadScope = READ_SCOPE_BY_BACKEND_TYPE[name];

  const showCreateDatasource = _some(accessPolicy.scopes, (scope) => scope === backendReadScope);
  const defaultScopesUsed = _intersection(accessPolicy.scopes, defaultScopes);
  const defaultScopesMissing = _difference(defaultScopes, defaultScopesUsed);

  const federatedQueriesEnabled = !!features.federated_queries;

  const tenantDescriptionForDatasource = federatedQueriesEnabled
    ? 'You can create a data source for your tenant(s) with a button click.'
    : `Your access policy grants access to multiple tenants. However, each data source can only query one tenant. Use the "Create data source" button to create a data source for each individual tenant you want to query.`;

  return (
    <Modal
      contentClassName={styles.modalContainer}
      isOpen={isOpen}
      onDismiss={onDismissAndClear}
      onClickBackdrop={onDismissAndClear}
      title={<div className={styles.modalTitle}>{title}</div>}
    >
      {/* Successful - showing the token */}
      {!!tokenWithSecret && (
        <div>
          {/* Description */}
          <div className={styles.copyTokenDescription}>Copy the token. It will be shown only once.</div>
          <div>You will not be able to see or generate it again. Losing a token requires creating a new one.</div>

          {/* Token */}
          <Field label="Token" className={styles.tokenField}>
            <div className={styles.tokenFieldWrapper}>
              <Input disabled value={tokenWithSecret?.token} placeholder="" />
              {tokenWithSecret?.token ? (
                <CopyToClipboard
                  className={styles.copyToClipboard}
                  label="Copy to clipboard"
                  textToCopy={tokenWithSecret?.token}
                  btnVariant="primary"
                />
              ) : null}
            </div>
          </Field>

          {/* Remote write config - only supporting metrics clusters yet */}
          {accessPolicy.scopes?.includes(Scope.MetricsWrite) && (
            <Field
              label="Remote-write config"
              description="You can use the following config to start sending data to your tenants"
              className={styles.scopeField}
            >
              <RemoteWriteConfigSnippet accessPolicy={accessPolicy} token={tokenWithSecret} />
            </Field>
          )}

          {/* Create data source for metrics, logs, or traces with read permissions */}
          {showCreateDatasource && !!tenants.length ? (
            <>
              <Field
                label="Create data source"
                description={tenantDescriptionForDatasource}
                className={styles.createDatesourceField}
              >
                <CreateDatasourceWidget tenants={tenants} accessPolicy={accessPolicy} token={tokenWithSecret} />
              </Field>
              {!!defaultScopesMissing.length && (
                <Alert title={'Missing scopes warning'} severity={'warning'}>
                  The token you created is associated with an access policy that does not include the{' '}
                  {JSON.stringify(defaultScopesMissing)} scopes. If you create a data source using this token, the
                  Alerting plugin might not work properly with it. Use a different token with the appropriate scopes or
                  modify the scopes for the access policy associated with this token to avoid this problem.
                </Alert>
              )}
            </>
          ) : (
            <>
              {!tenants.length && (
                <Alert title="No tenant error" severity="warning">
                  This token will not grant any access to the database because it is for an access policy with no active
                  tenants. To fix this, edit the access policy so that it is associated with an active tenant.
                </Alert>
              )}
              {!showCreateDatasource && (
                <Alert title={'Token usage warning'} severity={'warning'}>
                  The token you created is associated with an access policy that does not include the minimum read
                  permissions [{JSON.stringify(backendReadScope)}] to create a datasource. If you would like to create a
                  datasource using this token, modify the scopes for the access policy associated with this token.
                </Alert>
              )}
            </>
          )}

          {/* Actions */}
          <div className={styles.closeButton}>
            <Button onClick={onDismissAndClear} variant="secondary">
              Close
            </Button>
          </div>
        </div>
      )}

      {/* Showing the create form */}
      {shouldShowForm && (
        <>
          {shouldShowApiError && <ApiError actionType="tokens/create" className={styles.apiError} />}
          <CreateForm
            onCancel={onDismissAndClear}
            nameValidator={(tokenDisplayName) => {
              const tokenName = tokenNameGenerator(accessPolicy.name, tokenDisplayName);
              const existingToken = tokensByName[tokenName];
              switch (existingToken?.status) {
                case 'active':
                  return 'A similar token name is already in use. Try using a different one.';
                case 'inactive':
                  return 'A similar name was used for a token that has been deactivated. Try using a more unique name.';
              }
              return undefined;
            }}
            onSubmit={(data: TokenFormData) => {
              const token: Token = {
                // Prefixing the name with the name of the parent access policy, so
                // tokens with the same name under different access policies don't clash.
                name: tokenNameGenerator(accessPolicy.name, data.display_name),
                access_policy: accessPolicy.name,
                created_at: new Date(Date.now()).toISOString(),
                display_name: data.display_name,
                expiration: data.expiration ? parseISODate(data.expiration).toISOString() : undefined,
                status: 'active',
              };

              onCreate(token)
                .then(unwrapResult)
                .then(() => popAlert('token', 'created', token.display_name))
                .catch(() => setShouldShowApiError(true));
            }}
          />
        </>
      )}
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modalContainer: css`
      max-height: calc(80vh - 44px);
      overflow: visible;
    `,
    modalTitle: css`
      font-size: ${theme.typography.h4.fontSize};
      font-weight: normal;
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
    `,
    copyTokenDescription: css`
      font-weight: ${theme.typography.fontWeightBold};
    `,
    tokenField: css`
      margin-top: ${theme.spacing(3)};
    `,
    tokenFieldWrapper: css`
      display: flex;
    `,
    copyToClipboard: css`
      margin-left: ${theme.spacing(1)};
    `,
    scopeField: css`
      margin-top: 40px;
    `,
    createDatesourceField: css`
      margin-top: 40px;
      margin-bottom: 40px;
    `,
    closeButton: css`
      margin-top: ${theme.spacing(3)};
    `,
    apiError: css`
      margin-bottom: ${theme.spacing(2)};
    `,
  };
};
