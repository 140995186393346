import React, { ReactNode } from 'react';
import lodash from 'lodash'
import { cx } from '@emotion/css';

import { config } from '@grafana/runtime';
import { Alert } from '@grafana/ui';

import { License, LoadingIndicator } from '@common/components';
import { useGetAll } from '@common/state/licenses';
import { isLicensedThroughAWS, useUtilityStyles } from '@common/utils';

export const Licenses = () => {
  const s = useUtilityStyles();

  const { data, isError, error, isLoading } = useGetAll();
  const licenses = data?.items || [];

  // Grafana license info
  const { licenseInfo } = config;
  let licenseFormatted = {
    name: 'grafana_enterprise',
    display_name: 'Grafana Enterprise',
    token: {
      company: licenseInfo.edition,
      exp: licenseInfo.expiry,
    },
  };

  if (isError && lodash.isError(error)) {
    return <Alert severity="error" title={error.message} />;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (licenses && !licenses.length) {
    return <LicenseInfo>No licenses found.</LicenseInfo>;
  }

  if (licenses && isLicensedThroughAWS(licenses)) {
    return (
      <div className={s.marginTopLg}>
        <LicenseInfo>Licensed via AWS Marketplace.</LicenseInfo>
        <LicenseInfo>
          Check your{' '}
          <a
            href="https://console.aws.amazon.com/marketplace/home/subscriptions#/subscriptions?FulfillmentOptionType=Container"
            rel="noreferrer"
            target="_blank"
            className={s.paddingHorizontalXs}
          >
            AWS Marketplace Subscriptions
          </a>{' '}
          for more information.
        </LicenseInfo>
      </div>
    );
  }

  return (
    <>
      {licenses.map((item) => <License key={item.name} item={item} />)}
      {licenseInfo && <License key={licenseInfo.edition} item={licenseFormatted} />}
    </>
  );
};

const LicenseInfo: React.FC<{children: ReactNode}> = ({ children }) => {
  const s = useUtilityStyles();

  return <div className={cx(s.colorSemiWeak, s.justifyCenter, s.flex, s.marginTopSm)}>{children}</div>;
};
