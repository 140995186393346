import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal } from '@grafana/ui';

import { useGetAll } from '@common/state/accessPolicies';
import { AccessPolicy } from '@common/types';
import { popAlert, useUtilityStyles } from '@common/utils';

import { AccessPolicyForm } from '../AccessPolicyForm';
import { ApiError } from '../ApiError';

type Props = {
  isOpen: boolean;
  onCreate: (accessPolicy: AccessPolicy) => any;
  onDismiss: () => void;
};

export const CreateAccessPolicyModal = ({ isOpen, onCreate, onDismiss }: Props) => {
  const s = useUtilityStyles();
  const [showApiError, setShowApiError] = useState(false);

  const { entityDictionary: accessPoliciesByName } = useGetAll();

  // Close the modal, and reset api error display
  const dismissModal = () => {
    onDismiss();
    setShowApiError(false);
  };

  return (
    <Modal
      contentClassName={cx(
        css`
          max-height: calc(80vh - 44px);
        `
      )}
      isOpen={isOpen}
      onDismiss={dismissModal}
      onClickBackdrop={dismissModal}
      title={
        <div className={cx(s.textLg, s.textNormal, s.paddingVerticalSm, s.paddingHorizontalMd)}>
          Create new Access Policy
        </div>
      }
    >
      {showApiError && <ApiError actionType="accessPolicies/create" className={s.marginBottomMd} />}

      <AccessPolicyForm
        onCancel={dismissModal}
        nameValidator={(accessPolicyName) => {
          if (accessPolicyName.length < 3) {
            return 'Access policy name must be at least three characters long.';
          }
          const existingAccessPolicy = accessPoliciesByName[accessPolicyName];
          switch (existingAccessPolicy?.status) {
            case 'active':
              return 'You are already using this access policy name. Try using a different one.';
            case 'inactive':
              return 'This name was used for an access policy that has been deactivated. Try using a new name.';
          }
          return undefined;
        }}
        onSubmit={(data: AccessPolicy) => {
          onCreate({
            ...data,
            created_at: new Date(Date.now()).toISOString(),
          })
            .then(unwrapResult)
            .then(() => {
              onDismiss();

              popAlert('policy', 'created', data.display_name);
            })
            .catch(() => setShowApiError(true));
        }}
      />
    </Modal>
  );
};
