import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectRequestInfo } from '@common/state/requests';
import { deactivate, fetchAll, selectTokensForAccessPolicy } from '@common/state/tokens';
import { AccessPolicy, Token } from '@common/types';

import { TokensList } from './TokensList';

type Props = {
  accessPolicy: AccessPolicy;
  className?: string;
};

export const TokensListContainer = ({ accessPolicy, className }: Props) => {
  const dispatch = useDispatch();
  const items = useSelector(selectTokensForAccessPolicy(accessPolicy.name));
  const request = useSelector(selectRequestInfo('tokens/fetchAll'));
  const onDelete = (token: Token) => dispatch(deactivate(token));

  useEffect(() => {
    request.isNotFetched && dispatch(fetchAll());
  }, []); // eslint-disable-line

  return (
    <TokensList
      items={items}
      onDelete={onDelete}
      isLoading={request.isLoading}
      isError={request.isError}
      className={className}
    />
  );
};
