import React from 'react';
import { useSelector } from 'react-redux';
import { cx } from '@emotion/css';

import { Button, HorizontalGroup, Icon, IconButton, Spinner, Tooltip } from '@grafana/ui';

import { selectRequestInfo } from '@common/state/requests';
import { useTenantsForAccessPolicy } from '@common/state/tenants';
import { AccessPolicy } from '@common/types';
import { getDisplayName, isAccessPolicyReadOnly, isDefaultAccessPolicy, useUtilityStyles } from '@common/utils';

import { Collapse } from '../Collapse';
import { LabelSelectorVisualiser } from '../LabelSelectorVisualiser';
import { TokensList } from '../TokensList';

import { TenantSummary } from './TenantSummary';
import t from './text';

type Props = {
  /** The access policy object that is going to be displayed. */
  item: AccessPolicy;
  /** A callback that is called when the "Add token" button is clicked. */
  onCreateToken: (accessPolicy: AccessPolicy) => void;
  /** A callback that is going to be called when the delete icon is clicked. */
  onDelete: (accessPolicy: AccessPolicy) => void;
  /** A callback that is called when the user clicks the optional edit button. */
  onEdit?: () => void;
};

export const AccessPoliciesListItem = ({ item, onCreateToken, onDelete, onEdit }: Props) => {
  const tenants = useTenantsForAccessPolicy(item);
  const s = useUtilityStyles();
  const scopes = item.scopes || [];
  const tokenCount = item.tokens?.length || 0;

  const tokenRequest = useSelector(selectRequestInfo('tokens/fetchAll'));

  const Tokens = () => {
    if (tokenRequest.isLoading) {
      return (
        <HorizontalGroup>
          <Spinner size={11} />
          <TokensHeader title={t.pending} />
        </HorizontalGroup>
      );
    }

    if (tokenRequest.error) {
      return <TokensHeader title={`${tokenRequest.errorTitle} (${tokenRequest.errorMessage})`} />;
    }

    if (tokenCount === 0) {
      return (
        <>
          <TokensHeader title={t.noTokens} />
          {addTokenButton}
        </>
      );
    }

    return (
      <>
        <Collapse
          defaultIsOpen={false}
          className={cx(s.flex1, s.colorWeak)}
          title={<TokensHeader title={t.tokensLabel(tokenCount)} />}
        >
          <TokensList className={cx(s.flex1, s.paddingHorizontalMd)} accessPolicy={item} />
        </Collapse>
        {addTokenButton}
      </>
    );
  };

  const TokensHeader = ({ title }: { title: string }) => (
    <div className={cx(s.flex, s.flex1)}>
      <div className={s.flex1}>{title}</div>
    </div>
  );

  const addTokenButton = (
    <div className={cx(s.width100, s.flex, s.flexRow, s.justifyEnd)}>
      <Button
        variant="primary"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onCreateToken(item);
        }}
      >
        {t.addTokenButton}
      </Button>
    </div>
  );

  return (
    <>
      <div
        data-testid={`ap-container-${item.name}`}
        className={cx(s.colorSemiWeak, s.marginBottomMd, s.bgLevel2, s.block)}
      >
        {/* ROW #1 */}
        <div className={cx(s.flex, s.paddingVerticalMd, s.paddingHorizontalLg)}>
          {/* Name */}
          <div className={cx(s.flex1, s.wrap, s.textLg)}>
            <span className={s.colorStrong}>{getDisplayName(item)}</span>
            <div className={cx(s.textSm, s.colorWeak)}>{item.name}</div>

            {/* Default access policy */}
            {isDefaultAccessPolicy(item) && (
              <Tooltip
                content={<div className={cx(s.paddingHorizontalSm, s.textSm)}>{t.defaultAccessPolicyInfoContent}</div>}
                placement="top"
              >
                <div className={cx(s.colorWeak, s.textSm, s.marginTopMd)}>
                  <Icon name="info-circle" className={s.marginRightSm} />
                  {t.defaultAccessPolicyInfoTitle}
                </div>
              </Tooltip>
            )}
          </div>

          {/* Delete / Edit Access Policy */}
          {!isAccessPolicyReadOnly(item) && (
            <>
              <IconButton
                aria-label='Edit'
                className={cx(s.colorWeak, s.marginTopSm, s.marginLeftMd)}
                data-testid="ap-edit"
                name="edit"
                size="lg"
                disabled={!onEdit}
                onClick={onEdit}
              />

              <IconButton
                aria-label='Delete'
                className={cx(s.colorWeak, s.marginTopSm, s.marginLeftMd)}
                data-testid="ap-delete"
                name="trash-alt"
                size="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(item);
                }}
              />
            </>
          )}
        </div>

        {/* ROW #2 */}
        <div className={cx(s.paddingHorizontalLg)}>
          {/* Scopes */}
          <div data-testid="ap-permissions">
            {t.scopesLabel} <span className={s.colorWeak}>{scopes.length ? scopes.join(', ') : '-'}</span>
          </div>

          {/* Tenants */}
          <Collapse className={s.marginTopSm} defaultIsOpen={false} title={<TenantSummary tenants={tenants} />}>
            <div>
              {tenants.map((tenant) => (
                <div key={tenant?.name} className={cx(s.marginLeftLg, s.marginTopSm)}>
                  <div className={cx(s.flex1, s.wrap)}>
                    {tenant?.display_name}
                    <span className={cx(s.colorWeak, s.textSm, s.marginLeftXs)}>({tenant?.name})</span>
                  </div>

                  <div className={cx(s.colorWeak, s.textSm)}>
                    <Icon name="cloud" /> {tenant?.cluster}
                  </div>

                  {/* Label matchers */}
                  {tenant?.label_policies?.length
                    ? tenant?.label_policies.map(({ selector }) => (
                        <div
                          key={selector}
                          className={cx(
                            s.bgLevel3,
                            s.inlineBlock,
                            s.marginTopXs,
                            s.marginRightXs,
                            s.paddingHorizontalSm,
                            s.textSm
                          )}
                        >
                          <LabelSelectorVisualiser selector={selector} />
                        </div>
                      ))
                    : null}
                </div>
              ))}
            </div>
          </Collapse>
        </div>

        {/* ROW #3 */}
        {/* Tokens */}
        <div
          className={cx(
            s.flex,
            s.borderTop,
            s.borderSolid,
            s.borderVeryWeak,
            s.paddingVerticalMd,
            s.paddingHorizontalLg,
            s.marginTopMd
          )}
          data-testid="ap-tokens"
        >
          <Tokens />
        </div>
      </div>
    </>
  );
};
