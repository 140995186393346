import React from 'react';
import { License } from '@common/types';

import { Alert } from '@grafana/ui';

import { useGetAll } from '@common/state/licenses';
import { getExpiredAndNearExpiredLicenses } from '@common/utils';

export const LicenseAlerts = () => {
  const { data } = useGetAll();

  const { expired, nearExpired, notExpired } = getExpiredAndNearExpiredLicenses(data?.items);
  const numberOfLicenses = expired.length + notExpired.length;

  return (
    <>
      {expired.map((license) => (
        <Alert
          key={license.name}
          severity="error"
          title={
            `License ${getLicenseDisplay(license)} has expired. ` +
            `When unlicensed, only read-only access is permitted. ` +
            `Please contact Grafana Labs for renewal.`
          }
        />
      ))}
      {nearExpired.map((license) => (
        <Alert
          key={license.name}
          severity="warning"
          title={
            `License ${getLicenseDisplay(license)} is nearly expired. ` +
            `When unlicensed, only read-only access is permitted. ` +
            `Please contact Grafana Labs for renewal.`
          }
        />
      ))}
      {data != null && numberOfLicenses === 0 && (
        <Alert
          severity="error"
          title={
            `No licenses have been successfully installed for the current enterprise database. ` +
            `A valid license must be present when the database is initialized. If a license is expired or invalid, it will be ignored and not installed. ` +
            `When unlicensed, only read-only access is permitted. ` +
            `Please contact Grafana Labs for assistance with your license.`
          }
        />
      )}
    </>
  );
};

const getLicenseDisplay = (license: License) =>
  [
    `"${license.display_name}"`,
    license.token && license.token?.prod?.length ? `(for ${license.token.prod.join(', ')})` : '',
  ].join(' ');
