import React, { useContext } from 'react';

import { Alert } from '@grafana/ui';

import { Config as ConfigComponent, LoadingIndicator } from '@common/components';

import { BackendType } from '@common/types';
import { BackendContext, usePluginMeta } from '@common/utils';

// TODO, this should not be called "App Config" -- it should be called ClusterConfig
export const AppConfig: React.FC = () => {
  const { config } = useContext(BackendContext);

  if (!config) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <ConfigPageNote />
      <ConfigComponent config={config} />
    </>
  );
};

const ConfigPageNote = () => {
  const {
    backend: {
      name: backendName,
      implicitFeatures: { configFetchUrl },
    },
  } = useContext(BackendContext);
  const backendUrl = usePluginMeta().pluginMeta?.jsonData?.backendUrl;

  if (!backendName || !configFetchUrl || !backendUrl) {
    return null;
  }

  const { aboutConfigurationsUrl, configParamInfoUrl } = productInfo[backendName];

  const parameterValuesUrl = `${backendUrl.replace(/[\/]*$/, '')}${configFetchUrl}`;

  // Only show this is `aboutConfigurationsUrl` is defined
  const forMoreInfo = aboutConfigurationsUrl && (
    <>
      For more information, please see{' '}
      <a rel="noreferrer" target="_blank" href={aboutConfigurationsUrl}>
        About configurations
      </a>
      .
    </>
  );

  const forConfigParamsInfo = configParamInfoUrl && (
    <p>
      For documentation on the function of each configuration parameter, see{' '}
      <a rel="noreferrer" target="_blank" href={configParamInfoUrl}>
        Configuration reference
      </a>
      .
    </p>
  );

  return (
    <Alert severity="info" title="A note about this cluster configuration page">
      <p>
        The parameter values shown below are those returned by {parameterValuesUrl}. If you have configured other
        components in your cluster with different parameter values, those will not be reflected below. {forMoreInfo}
      </p>
      {forConfigParamsInfo}
    </Alert>
  );
};

const productInfo: Record<BackendType, { aboutConfigurationsUrl?: string; configParamInfoUrl?: string }> = {
  GEL: {
    configParamInfoUrl: 'https://grafana.com/docs/enterprise-logs/latest/config/reference/',
  },
  GEM: {
    aboutConfigurationsUrl: 'https://grafana.com/docs/mimir/latest/operators-guide/configuring/about-configurations/',
    configParamInfoUrl: 'https://grafana.com/docs/enterprise-metrics/latest/config/reference/',
  },
  GET: {
    configParamInfoUrl: 'https://grafana.com/docs/enterprise-traces/latest/config/reference/',
  },
};
