import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { CurrentTimeUTC } from '@common/components';

type Props = {
  onCreate: () => void;
  createType: 'tenant' | 'access policy';
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    buttonWrapper: css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${theme.spacing(1)};
  `,
    timeWrapper: css`
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-bottom: ${theme.spacing(1)};
      margin-top: ${theme.spacing(1)};
    `,
  };
};

export const AdminPageActionsBar: React.FC<Props> = ({ onCreate, createType }) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {/* Create new */}
      <div className={styles.buttonWrapper}>
        <Button variant="primary" size="md" onClick={onCreate}>
          Create {createType}
        </Button>
      </div>

      {/* Current UTC time */}
      <div className={styles.timeWrapper}>
        <CurrentTimeUTC />
      </div>
    </>
  );
};
